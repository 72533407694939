/* eslint-disable no-underscore-dangle */
Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

let _index = _interopRequireDefault(require('./_lib/formatDistance/index.js'));

let _index2 = _interopRequireDefault(require('./_lib/formatLong/index.js'));

let _index3 = _interopRequireDefault(require('./_lib/formatRelative/index.js'));

let _index4 = _interopRequireDefault(require('./_lib/localize/index.js'));

let _index5 = _interopRequireDefault(require('./_lib/match/index.js'));

/**
 * @type {Locale}
 * @category Locales
 * @summary Spanish locale.
 * @language Spanish
 * @iso-639-2 spa
 * @author Juan Angosto [@juanangosto]{@link https://github.com/juanangosto}
 * @author Guillermo Grau [@guigrpa]{@link https://github.com/guigrpa}
 * @author Fernando Agüero [@fjaguero]{@link https://github.com/fjaguero}
 * @author Gastón Haro [@harogaston]{@link https://github.com/harogaston}
 * @author Yago Carballo [@YagoCarballo]{@link https://github.com/YagoCarballo}
 */
const locale = {
  formatDistance: _index.default,
  formatLong: _index2.default,
  formatRelative: _index3.default,
  localize: _index4.default,
  match: _index5.default,
  options: {
    weekStartsOn: 1,
    /* Monday */
    firstWeekContainsDate: 1,
  },
};

exports.default = locale;
module.exports = exports.default;
