import React from 'react';

import './HighlightButton.scss';

type Props = {
  text: string;
  onClick(e?: any): void;
  className?: string;
};

export default function HighlightButton({ text, className = '', onClick }: Props) {
  return (
    <div
      className={`highlightbutton ${className}`}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      {text}
    </div>
  );
}
