Object.defineProperty(exports, '__esModule', {
  value: true,
});

function buildFormatLongFn(args) {
  return function (dirtyOptions) {
    const options = dirtyOptions || {};
    const width = options.width ? String(options.width) : args.defaultWidth;
    const format = args.formats[width] || args.formats[args.defaultWidth];
    return format;
  };
}

exports.default = buildFormatLongFn;
module.exports = exports.default;
