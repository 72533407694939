Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const index = _interopRequireDefault(require('../../../_lib/buildFormatLongFn/index.js'));

const dateFormats = {
  full: "EEEE, d 'de' MMMM y",
  long: "d 'de' MMMM y",
  medium: 'd MMM y',
  short: 'dd/MM/y',
};
const timeFormats = {
  full: 'HH:mm:ss zzzz',
  long: 'HH:mm:ss z',
  medium: 'HH:mm:ss',
  short: 'HH:mm',
};
const dateTimeFormats = {
  full: "{{date}} 'a las' {{time}}",
  long: "{{date}} 'a las' {{time}}",
  medium: '{{date}}, {{time}}',
  short: '{{date}}, {{time}}',
};
const formatLong = {
  date: (0, index.default)({
    formats: dateFormats,
    defaultWidth: 'full',
  }),
  time: (0, index.default)({
    formats: timeFormats,
    defaultWidth: 'full',
  }),
  dateTime: (0, index.default)({
    formats: dateTimeFormats,
    defaultWidth: 'full',
  }),
};

exports.default = formatLong;
module.exports = exports.default;
