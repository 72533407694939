import React, { PureComponent } from 'react';

export default class ContentDivisionHeader extends PureComponent<Props> {
  render() {
    const { smallTitleDescription, title, centerComponent, endText, containerStyle } = this.props;

    return (
      <div className="content-division-header" style={{ ...containerStyle }}>
        <div className="row no-gutters align-items-baseline justify-content-between">
          <div className="col-auto">
            <h3 style={{ display: 'inline' }}>{title}</h3>
            {smallTitleDescription
            && <span className="text-smaller" style={{ marginLeft: 7 }}>{smallTitleDescription}</span>}
          </div>
          {centerComponent
          && (
            <div className="col-auto">
              {centerComponent}
            </div>
          )}
          <div className="col-auto">
            <p className="text-muted text-smallest">{endText}</p>
          </div>
        </div>
      </div>
    );
  }
}

type Props = {
  title: string;
  centerComponent?: any;
  smallTitleDescription?: string;
  endText?: string;
  containerStyle?: any;
};
